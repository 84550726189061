//This mixins in common code into
//EditJobDescription.vue & ViewJobDescription.vue

const mixin_initJobDescriptionForms = {
    data(){
        return{
            //Initially set all database values to null
            valid: false,
            job_description_id: null,
            job_description_name: '',
            job_description_status: 'Available',
            job_description_date: null,
            job_description_timestamp: null,
        }
    },
}

export default mixin_initJobDescriptionForms