<template>
    <div>
        <JobDescriptionsTable />
    </div>
</template>

<script>
import JobDescriptionsTable from '../components/JobDescriptionsTable'
export default {
    name: 'JobDescriptions',
    components: {JobDescriptionsTable}
}
</script>