<template>
    <v-container  fluid>
    <v-row dense>
        <v-col lg="8" offset-lg="2">
                <FormHeader
                    v-bind='editJobDescriptionFormHeader'
                />
                <v-card>
                <!--New Job Description form -->
                <v-form @submit.prevent="updateJobDescription" class="pa-3" ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="job_description_name"
                                    :rules="[RequireFormInput('job description name'), minLength('job description name',2), maxLength('job description name',50)]"
                                    counter="50"
                                    label="Job Description Name"
                                    placeholder="Eg: Branch Manager"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-select
                                    v-model="job_description_status"
                                    :items="['Available', 'Unavailable']"
                                    label="Status"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/job-descriptions'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Cancel
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <!--Delete Customer button-->
                    <v-btn
                        @click='deleteJobDescription'
                        v-show="this.user_roles_list.flat().includes('employees_delete')"
                        color="#ce2458"
                        text
                        x-large
                        :loading="loading_delete"
                    >
                        Delete
                        <v-icon right>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                        @click="updateJobDescription"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading_update"
                    >
                        Update
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
//Connect to database
import db from '../../../components/firebaseInit'
import FormHeader from '../../../components/Forms/FormHeader'
import store from '../store/store'
import {mapState} from 'vuex'
//Mixins
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import mixin_formValidation from '../mixins/mixin_formValidation'
import mixin_initJobDescriptionForms from '../mixins/mixin_initJobDescriptionForms'
import {showSnackbar} from '../../../globalActions/index'

export default {
    name: 'EditJobDescription',
    components: {FormHeader},
    mixins: [
        mixin_UserRoles, mixin_formValidation,
        mixin_initJobDescriptionForms
    ],
    data(){
        return{
            loading_update: false,
            loading_delete: false,
        }
    },
    //Database query that runs before the page loads
    beforeRouteEnter (to, from, next){
        db.collection('job_descriptions').where('job_description_id', '==', to.params.job_description_id).get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc =>{
                //vm assigns all the values we want to put in the template
                next(vm => {
                    //Sets template values to those stored in the database
                    vm.job_description_id = doc.data().job_description_id,
                    vm.job_description_name = doc.data().job_description_name,
                    vm.job_description_status = doc.data().job_description_status                    
                })
            })
        })
    },
    watch: {
        '$route': 'fetchData'
    },
    store,
    computed: mapState ({
         //This is where data is retrieved from the Vuex state
        editJobDescriptionFormHeader:state => state.editJobDescriptionFormHeader
    }),
    methods: {
        //Maps database data to template relative to the customer selected in this route
        fetchData () {
        db.collection('job_descriptions').where('job_description_id', '==', this.$route.params.job_description_id).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            db.job_description_id = doc.data().job_description_id
                this.job_description_name = doc.data().job_description_name
                this.job_description_status = doc.data().job_description_status
                
          })
        })
      },
        //Updates form values in the database
        updateJobDescription () {
            //Form validation --> ensures required fields aren't blank before continuing further
            if(this.$refs.form.validate()){
                this.loading_update = true
                db.collection('job_descriptions').where('job_description_id', '==', this.$route.params.job_description_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.update({
                            job_description_id: this.job_description_id ,
                            job_description_name: this.job_description_name ,
                            job_description_status: this.job_description_status,
                        })
                        //Reroute to job description table page after database has been updated
                        .then(() => {
                            this.loading_update = false
                            showSnackbar("Job Description updated successfully");
                            this.$router.push('/job-descriptions')
                        })
                    })
                })
            }
            
        },
        //Removes job description from database
        deleteJobDescription () {
            if(confirm('Are you sure you want to delete this job description?')){
                this.loading_delete = true
                db.collection('job_descriptions').where('job_description_id', '==', this.$route.params.job_description_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.delete()
                        //Redirects back to job descriptions list page
                        this.loading_delete = false
                        showSnackbar("Job Description deleted successfully");
                        this.$router.push('/job-descriptions')
                    })
                })
            }
        }
    }
}
</script>