<template>
    <v-container  fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('employees_write')">
        <v-col lg="8" offset-lg="2">
            <FormHeader
                    v-bind='newJobDescriptionFormHeader'
                />
                
                <v-divider></v-divider>
            <v-card>
                <!--New Job Description form -->
                <v-form @submit.prevent="saveJobDescription" class="pa-3" ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="job_description_name"
                                    :rules="[RequireFormInput('job description name'), minLength('job description name',2), maxLength('job description name',50)]"
                                    counter="50"
                                    label="Job Description Name"
                                    placeholder="Eg: Branch Manager"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-select
                                    v-model="job_description_status"
                                    :items="['Available', 'Unavailable']"
                                    label="Status"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/job-descriptions'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Cancel
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                        @click="saveJobDescription"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading"
                    >
                        Submit
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <div v-else>
        <NoPermissionsCard/>
    </div>
</v-container>
</template>

<script>
//Connect to database
import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import store from '../store/store'
import {mapState} from 'vuex'
import FormHeader from '../../../components/Forms/FormHeader'
//Mixins
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import mixin_formValidation from '../mixins/mixin_formValidation'
import mixin_initJobDescriptionForms from '../mixins/mixin_initJobDescriptionForms'
import {showSnackbar} from '../../../globalActions/index'
//Components
import NoPermissionsCard from '../../../components/Cards/NoPermissionsCard'

export default {
    name: 'NewJobDescription',
    components: {FormHeader, NoPermissionsCard},
    mixins: [
        mixin_UserRoles, mixin_formValidation, mixin_initJobDescriptionForms
    ],
    
    data(){
        return{
            value: '',
            loading: false,
            job_description_created_by_id: null,
            job_description_created_by_name: null,
        }
    },    
    store,
    computed: mapState ({
        //This is where data is retrieved from the Vuex state
        newJobDescriptionFormHeader: state => state.newJobDescriptionFormHeader
    }),
    created(){
        //Captures user that created the sales order
        this.job_description_created_by_id = firebase.auth().currentUser.uid
            db.collection('users').where('uid', '==', this.job_description_created_by_id).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
            this.job_description_created_by_name = doc.data().displayName
            })
        })
    },
    methods: {
        saveJobDescription () {
            //Form validation --> ensures required fields aren't blank before continuing further
            if(this.$refs.form.validate()){
                this.loading = true
                var newDocRef = db.collection('job_descriptions').doc();
                newDocRef.set({
                    //Populated by the v-model values in the form
                    //Sets the job_description_id == to the document id in database
                    job_description_id: newDocRef.id,
                    job_description_name: this.job_description_name,
                    job_description_status: this.job_description_status,
                    job_description_date: new Date().toISOString().slice(0, 10),
                    job_description_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    job_description_created_by_id: this.job_description_created_by_id,
                    job_description_created_by_name: this.job_description_created_by_id,
                })
                //Reroute to customers list after database is updated
                .then(() => {
                    this.loading = false
                    showSnackbar("New job description added successfully");
                    this.$router.push('/job-descriptions')
                })
            }
        }
    }
}
</script>