<template>
    <div>
         <v-container fluid>
            <v-row class="mb-8">
                <v-col lg="12">
                    <v-card-title>
                        <h2>Job Descriptions</h2>
                        <!--Skeleton button-->
                        <v-skeleton-loader
                          :loading="loading_user"
                          type="button"
                          v-if="firstLoad"
                          class="ml-10"
                        >
                        </v-skeleton-loader>
                        <!--'Add Job Description' button below-->
                        <NewEmployeeButton
                          v-if="!firstLoad"
                          v-show="this.user_roles_list.flat().includes('employees_write')"
                        />
                        <ButtonCTA
                            :button_name="viewEmployeesBtn.btn_employees_name"
                            :button_to="viewEmployeesBtn.btn_employees_to"
                            :button_color="secondaryCTA.btn_secondary_color"
                            :button_icon="secondaryCTA.btn_view_icon"
                            :button_margin_left="secondaryCTA.btn_ml_10"
                            v-show="this.user_roles_list.flat().includes('employees_read')"
                            v-if="!firstLoad"
                        >
                        </ButtonCTA>
                    </v-card-title>
                </v-col>
            </v-row>
            <!--Table skeleton loader-->
              <v-row v-if="firstLoad">
                <v-col>
                  <v-sheet elevation="4">
                    <v-skeleton-loader :loading="loading_user" type="table" max-height="500">
                    </v-skeleton-loader>
                  </v-sheet>
                </v-col>
              </v-row>
              <!--End of skeleton loader-->
            <v-row v-if="!firstLoad" v-show="this.user_roles_list.flat().includes('employees_read')">
              <v-col>
                <v-sheet elevation="4">
                    <!--Customer data table-->    
                    <template>
                      <div>
                        <v-data-table
                          :headers="jobDescriptionsTableHeaders"
                          :items="filteredItems"
                          :search="search"
                          sort-by="job_description_name"
                        >
                        <template v-slot:top>
                      <!--Filter products buy availability status--> 
                        <v-container fluid>
                          <v-row>
                            <v-col lg="3" md="3">
                              <v-select
                                :items="['Available','Unavailable']"
                                v-model='availableStatus'
                                label="Availability Status"
                                outlined
                                flat
                                solo
                                dense
                                append-icon="mdi-filter-variant"
                              >
                                  <template>
                                    <v-list-item slot="prepend-item">
                                        <h5>Filter by Availability</h5>
                                    </v-list-item>
                                </template>
                              </v-select> 
                            </v-col>  
                            <v-spacer />
                            <v-col lg="4" md="4">
                                <v-text-field
                                  v-model="search"
                                  append-icon="mdi-magnify"
                                  label="Search"
                                  single-line
                                  hide-details
                                  rounded
                                  dense
                                  filled
                                  clearable
                                ></v-text-field>
                            </v-col>
                            </v-row>
                        </v-container>
                      </template>
                        <template
                          v-slot:item.job_description_id={item}
                          v-if="this.user_roles_list.flat().includes('employees_write')"  
                        >
                            <v-btn
                                :to="{ name: 'edit-job-description', params: { job_description_id: item.job_description_id }}"
                                color="#3d2cdd"
                                icon
                                small
                            ><v-icon small>mdi-pencil</v-icon></v-btn>
                        </template>
                        <!--Disable edit button based on user roles-->
                        <template
                          v-slot:item.job_description_id
                          v-else
                        >
                          <v-btn
                                color="#3d2cdd"
                                icon
                                small
                                disabled
                            ><v-icon small>mdi-pencil-off</v-icon></v-btn>
                        </template>
                        <template v-slot:item.job_description_status="props">
                            <v-icon 
                            left
                            x-small
                            :color="formatAvailability(props.item.job_description_status)">mdi-circle</v-icon>{{props.item.job_description_status}}
                        </template>
                        <template v-slot:no-data>
                          <v-container>
                            <v-row>
                              <v-col>
                                <div class="my-3">
                                  <h3>Welcome To Your Job Descriptions List</h3>
                                  <p>
                                      Here you can add or edit job descriptions that<br>
                                      can be assigned to your staff members
                                  </p>
                                  <ButtonCTA
                                    class="white-text"
                                    :button_name="addJobDescriptionBtn.btn_add_job_description"
                                    :button_to="addJobDescriptionBtn.btn_new_job_description_to"
                                    :button_color="primaryCTA.btn_primary_color"
                                    :button_icon="primaryCTA.btn_plus_icon"
                                  />
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                      </template>
                        </v-data-table>
                      </div>
                    </template>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import db from '../../../components/firebaseInit' //imports firebase database that was specified in firebaseInit.js
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import NewEmployeeButton from '../components/NewEmployeeButton'
import ButtonCTA from '../../../components/Buttons/ButtonCTA'
import store from '../store/store'
import {mapState} from 'vuex'
export default {
    name: 'JobDescriptionsTable',
    mixins: [mixin_UserRoles],
    components: {NewEmployeeButton, ButtonCTA},
    data () {
      return {
        pagination: {},
        search: '',
        job_descriptions: [],
        //Used with filtering table by availability
        availableStatus: 'Available',
      }
    },
    // Gets categorie from firebase datatbase
    created(){
      db.collection('job_descriptions').get().then(querySnapshot => {
        this.job_descriptions = []
        querySnapshot.forEach(doc => {
          const data = {
            'id': doc.id,
            'job_description_id': doc.data().job_description_id,
            'job_description_name': doc.data().job_description_name,
            'job_description_status': doc.data().job_description_status,
            'job_description_date': doc.data().job_description_date,
            
          }
          //Pushes the categories objects to the empty categories array above
          this.job_descriptions.push(data)
        })
      })
    },
    store,
    computed: mapState({
      filteredItems() {
        return this.job_descriptions.filter((i) => 
          {
            return this.availableStatus === '' || (i.job_description_status === this.availableStatus)
          })
        },
        //This is where data is retrieved from the Vuex state
        primaryCTA: state => state.primaryCTA,
        addJobDescriptionBtn: state =>state.addJobDescriptionBtn,
        viewEmployeesBtn: state => state.viewEmployeesBtn,
        secondaryCTA: state => state.secondaryCTA,
        jobDescriptionsTableHeaders: state => state.jobDescriptionsTableHeaders
    }),
    methods: {
      formatAvailability (job_description_status) {
        if (job_description_status == 'Available') return '#33cc99'
        else return '#f77f9c'
      },
    }
}
</script>

<style scoped>
  .white-text{
    color: #fff;
  }
</style>